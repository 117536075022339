<template>
  <!--  <pre>{{ vv.$errors }}</pre>-->
  <div class="main">

    <!-- Comercializador -->
    <div v-if="selectedRoleId===2 && bEdit">
      <div class="flex justify-content-center">
        <Dropdown v-model="content"
                  :options="comercializadores"
                  optionValue="id_comercializador"
                  optionLabel="tcomercializador"
                  placeholder="Seleccionar Bloque"
                  :onClick="refreshComercializador"
                  :onChange="setEntityId"
                  class="drop"
                  :loading="loading"
                  :showClear="true"
                  :filter="true"
                  style="width: 100%; cursor: default"/>


      </div>
      <small v-if="vv.$errors" class="p-error">{{ vv.$errors[0] && vv.$errors[0].$message }}</small>
    </div>
    <!-- Banca -->
    <div v-if="selectedRoleId===3 && bEdit">
      <div class="flex justify-content-center">
        <Dropdown
            v-model="selectedEntityId"
            :options="bancas"
            optionValue="id_banca"
            optionLabel="tbanca"
            placeholder="Seleccionar Banca"
            :onClick="refreshBanca"
            :onChange="setEntityId"
            class="drop"
            :loading="loading"
            :showClear="true"
            :filter="true"
            style="width: 100%; cursor: default"/>

      </div>
      <small v-if="vv.$errors" class="p-error">{{ vv.$errors[0] && vv.$errors[0].$message }}</small>
    </div>
    <!-- Distribuidor -->
    <div v-if="selectedRoleId===4 && bEdit">
      <div class="flex justify-content-center">

        <Dropdown
            v-model="selectedEntityId" :options="distribuidores"
            optionValue="id_distribuidor"
            optionLabel="tdistribuidor"
            placeholder="Seleccionar Distribuidor"
            :onClick="refreshDistribuidor"
            :onChange="setEntityId"
            class="drop"
            :loading="loading"
            :showClear="true"
            :filter="true"
            style="width: 100%; cursor: default"/>

      </div>
      <small v-if="vv.$errors" class="p-error">{{ vv.$errors[0] && vv.$errors[0].$message }}</small>
    </div>

    <!-- Agencia -->
    <div v-if="selectedRoleId===5 && bEdit">
      <Dropdown
          v-model="selectedEntityId" :options="agencias"
          optionValue="id_agencia"
          optionLabel="tagencia"
          placeholder="Seleccionar Agencia"
          :onClick="refreshAgencia"
          :onChange="setEntityId"
          class="drop"
          :loading="loading"
          :showClear="true"
          :filter="true"/>
      <small v-if="vv.$errors" class="p-error">{{ vv.$errors[0] && vv.$errors[0].$message }}</small>
    </div>
  </div>

</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import {required, helpers} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {ref} from "vue";

export default {
  name: "EntityFilter",
  props: {
    selectedRoleId: Number,
    entity_id: Number,
    selectedInput: {
      type: Number,
    },
    nonEmptyField: String,
    title: String,
    input: {
      type: Number,
    },
  },
  setup(props) {
    const content = ref(props.entity_id);//ref(this.content);//ref(88)
    const mandatoryField = 'Campo obligatorio';
    const rules = {
      content: {
        required: helpers.withMessage(mandatoryField, required),
        $autoDirty: true
      },
    }
    const vv = useVuelidate(rules, {content})
    return {content, vv}
  },
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService();
    this.theDate = this.getYesterdayString()

    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
    // this.entity_id = this.selectedInput;
  },
  data() {
    return {
      selectedComercializador: null,
      selectedBanca: null,
      selectedDistribuidor: null,
      selectedAgencia: null,
      selectedLoteria: null,
      selectedEntityId: this.entity_id,
      comercializadores: null,
      bancas: null,
      distribuidores: null,
      agencias: null,
      loading: false,
      bEdit: true,
      identity: null,
      // entity_id: null,
    }
  },
  methods: {
    async refreshComercializador() {
      this.loading = true;
      this.selectedComercializador = null;
      this.comercializadores = await this.mainService.getComercializador({
        fecha: this.theDate,
        identity: this.identity,
        page: 'users',
        hide_external: true,
      });
      this.loading = false;
    },
    async refreshBanca() {
      this.loading = true;
      this.selectedBanca = null;
      this.bancas = await this.mainService.getBanca({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        page: 'users',
        hide_external: true,
      });
      this.loading = false;
    },
    async refreshDistribuidor() {
      this.loading = true;
      this.selectedDistribuidor = null;
      this.distribuidores = await this.mainService.getDistribuidor({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        page: 'users',
        hide_external: true,
      });
      this.loading = false;
    },
    async refreshAgencia() {
      this.loading = true;
      this.selectedAgencia = null;
      this.agencias = await this.mainService.getAgencia({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        id_distribuidor: this.selectedDistribuidor,
        page: 'users',
        hide_external: true,
      });
      this.loading = false;
    },
    setEntityId(event) {
      this.$emit('setEntityId', event)
    },
    getValue() {
      this.loading = true;
      let result = null;
      let response = null;
      // Comercializador
      if (this.comercializadores && this.selectedRoleId === 2 && this.entity_id) {
        let result = this.comercializadores.filter(item => item.id_comercializador === this.entity_id);
        response = result[0].tcomercializador;
      }
      // Banca
      if (this.comercializadores && this.selectedRoleId === 3) {
        if (!this.bancas) {
          this.refreshBanca();
        }
        if (this.entity_id) {
          result = this.bancas.filter(item => item.id_banca === this.entity_id);
          response = result[0] ? result[0].tbanca : null;
        }
      }
      // Distribuidor
      if (this.comercializadores && this.selectedRoleId === 4) {
        if (!this.distribuidores) {
          this.refreshDistribuidor();
        }
        if (this.entity_id) {
          let result = this.distribuidores.filter(item => item.id_distribuidor === this.entity_id);
          response = result[0].tdistribuidor;
        }
      }
      this.loading = false;
      return response;
    }
  },
}
</script>

<style scoped>
.main {
  /*padding: 10px;*/
  /*margin: 10px;*/
}

.field-title {
  color: var(--green-banklot);
  font-weight: bold;
  margin-bottom: 10px;
}

.content {
  margin-left: 10px;
}

.drop {
  height: 33px;
  width: 100%;
}
</style>